import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "@shared/config";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class OffredListService {
    public constructor(
        private http: HttpClient,
        private readonly config: ConfigService,
    ) {}

    public transferList(cryptedId: string) {
        const url: string = `${this.config.get("apiEndPoint")}`;
        const apiToken: string = `${this.config.get("apiToken")}`;
        const httpOptions: object = {
            headers: new HttpHeaders().set("Authorization", apiToken),
        };
        return this.http.get(`${url}/lists/${cryptedId}/transfer`, httpOptions).pipe(catchError(this.handleError));
    }

    public updateListForTransfer(dataListForUpdate: any) {
        const url: string = `${this.config.get("apiEndPoint")}`;
        const apiToken: string = `${this.config.get("apiToken")}`;
        const httpOptions: object = {
            headers: new HttpHeaders().set("Authorization", apiToken),
        };
        return this.http
            .patch(
                `${url}/lists/${sessionStorage.getItem("ng2-webstorage|current-list")}`,
                dataListForUpdate,
                httpOptions,
            )
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(`Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error("Something bad happened; please try again later."));
    }
}
