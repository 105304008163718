import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class PopinMsgDestinationService {
    private readonly isOpenLoaderSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public dataPopin$: Observable<boolean> = this.isOpenLoaderSource.asObservable();

    private toggleVar: boolean = false;

    public toggle() {
        this.toggleVar = !this.toggleVar;
        this.isOpenLoaderSource.next(this.toggleVar);
    }
}
