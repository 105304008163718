import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { BrandService } from "@shared/services/brand.service";
import { TypeService } from "@shared/services/type.service";
import { Brand, Type } from "./models";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { LoaderService } from "@shared/services/loader.service";

@Component({
    selector: "mv-wedding-list",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    public brand: Brand;
    private type: Type;
    private favicon: HTMLLinkElement = document.querySelector("#favicon")!;
    private body: HTMLBodyElement = document.querySelector("body")!;

    constructor(
        private titleService: Title,
        private brandService: BrandService,
        private typeService: TypeService,
        public loaderservice: LoaderService,
        public router: Router,
    ) {}

    public ngOnInit() {
        this.setBrandAndType();
        const title = `Liste de ${this.type === "wedding" ? "Mariage" : "Voyage"} ${
            this.brand === "mv" ? "Marco Vasco" : "Prestige Voyages"
        }`;
        this.titleService.setTitle(title);
        this.brandService.updateBrand(this.brand);
        this.typeService.updateType(this.type);
        !!this.favicon && (this.favicon.href = `/assets/img/${this.brand}/favicon.ico`);
        this.body.classList.add(this.brand);
    }

    private setBrandAndType(): void {
        const url = window.location.hostname;

        switch (url) {
            case "listedemariage.marcovasco.fr":
            case "preprod.listedemariage.marcovasco.fr":
            case "testing.listedemariage.marcovasco.fr":
            case "wip.listedemariage.marcovasco.fr":
                this.type = "wedding";
                this.brand = "mv";
                break;

            case "listedemariage.prestige-voyages.com":
            case "preprod.listedemariage.prestige-voyages.com":
            case "testing.listedemariage.prestige-voyages.com":
            case "wip.listedemariage.prestige-voyages.com":
                this.type = "wedding";
                this.brand = "pv";
                break;

            case "listedevoyage.marcovasco.fr":
            case "preprod.listedevoyage.marcovasco.fr":
            case "testing.listedevoyage.marcovasco.fr":
            case "wip.listedevoyage.marcovasco.fr":
                this.type = "travel";
                this.brand = "mv";
                break;

            case "listedevoyage.prestige-voyages.com":
            case "preprod.listedevoyage.prestige-voyages.com":
            case "testing.listedevoyage.prestige-voyages.com":
            case "wip.listedevoyage.prestige-voyages.com":
                this.type = "travel";
                this.brand = "pv";
                break;

            case "lists-view.travel.local":
                this.type = "travel";
                this.brand = "mv";
                break;

            case "lists-view.pv.local":
                this.type = "wedding";
                this.brand = "pv";
                break;

            case "lists-view.travel.pv.local":
                this.type = "travel";
                this.brand = "pv";
                break;

            default:
                this.type = "wedding";
                this.brand = "mv";
        }
    }
}
