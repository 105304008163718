import { registerLocaleData } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { BrandService } from "@shared/services/brand.service";
import { TypeService } from "@shared/services/type.service";

import localeFr from "@angular/common/locales/fr";

registerLocaleData(localeFr);

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardGuard } from "@modules/dashboard/dashboard.guard";
import { ContractGcv } from "@shared/components/contractGcv/contractGcv.component";
import { LoaderGcvService } from "@shared/services/loaderGcv.service";
import { ContractDataService } from "@shared/services/contractData.service";
import { LoaderService } from "@shared/services/loader.service";
import { PopinNicePresent } from "@shared/components/popinNicePresent/popinNicePresent.component";
import { OffredListService } from "@modules/dashboard/offeredList/offredList.service";
import { PopinNicePresentService } from "@shared/services/popinNicePresent.service";
import { PopinMsgDestinationService } from "@shared/services/popinMsgDestination";
import { PopinMsgDestination } from "@shared/components/popinMsgDestination/popinMsgDestination.component";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, ContractGcv, PopinNicePresent, PopinMsgDestination],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule],
    providers: [
        { provide: LOCALE_ID, useValue: "fr-FR" },
        BrandService,
        TypeService,
        LoaderService,
        DashboardGuard,
        LoaderGcvService,
        ContractDataService,
        OffredListService,
        PopinNicePresentService,
        PopinMsgDestinationService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
