import { Injectable } from "@angular/core";

import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
} from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable()
export class LoaderService {
    public loading$: Observable<boolean>;
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public constructor(protected readonly router: Router) {
        this.loading$ = this.loadingSubject.asObservable().pipe(debounceTime(200));
        this.router.events.subscribe((routerEvent: RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    public startLoader(): void {
        this.loadingSubject.next(true);
    }
    public stopLoader(): void {
        this.loadingSubject.next(false);
    }

    protected checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.loadingSubject.next(true);
        }

        if (
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationError
        ) {
            this.loadingSubject.next(false);
        }
    }
}
