import { Component, OnInit } from "@angular/core";
import { Brand, IPopinNicePresent, Type } from "@models/interfaces";
import { BrandService } from "@shared/services/brand.service";
import { PopinMsgDestinationService } from "@shared/services/popinMsgDestination";
import { TypeService } from "@shared/services/type.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "mv-popin-msgdestination",
    styleUrls: ["./popinMsgDestination.component.scss"],
    templateUrl: "./popinMsgDestination.component.html",
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PopinMsgDestination implements OnInit {
    public brand: Brand;
    public type: Type;
    public displayPopin: boolean;
    public message: string;
    public cryptedId: string;
    public transfertStatus: boolean = false;

    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private brandService: BrandService,
        private typeService: TypeService,
        public popinMsgDestinationService: PopinMsgDestinationService,
    ) {}

    ngOnInit() {
        this.brandService.brand$.pipe(takeUntil(this.destroy$)).subscribe((brandVal) => {
            this.brand = brandVal;
        });

        this.typeService.type$.pipe(takeUntil(this.destroy$)).subscribe((typeVal) => {
            this.type = typeVal;
        });
        this.popinMsgDestinationService.dataPopin$.subscribe((val: boolean) => {
            this.displayPopin = val;
        });
    }

    public togglePopin(): void {
        this.popinMsgDestinationService.toggle();
    }
}
