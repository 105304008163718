import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class PopinNicePresentService {
    private readonly isOpenLoaderSource: BehaviorSubject<object> = new BehaviorSubject({
        displayPopin: false,
        messagePopin: "",
    });
    public dataPopin$: Observable<object> = this.isOpenLoaderSource.asObservable();

    private toggleVar: boolean;

    public toggle(message?: string, cryptedId?: string) {
        this.toggleVar = !this.toggleVar;

        const dataPopinNicePresent = {
            displayPopin: this.toggleVar,
            messagePopin: message,
            cryptedId: cryptedId,
        };

        this.isOpenLoaderSource.next(dataPopinNicePresent);
    }
}
