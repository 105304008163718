import { IConfig, IConfigData, IConfigProperty } from "./interfaces";

export class Config implements IConfig {
    protected properties: Map<string, IConfigProperty>;

    public constructor(data: IConfigData | undefined) {
        this.properties = new Map();

        // Load config
        if (null != data) {
            Object.keys(data).forEach((key) => {
                this.properties.set(key, Reflect.get(data, key));
            });
        }
    }

    public get(key: string): IConfigProperty | undefined {
        return this.properties.get(key);
    }
}
