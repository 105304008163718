<mv-popup [show]="displayPopin" (popin-closed)="popinNicePresentService.toggle()">
    <div class="popin__message">
        <p>
            Lorsque vous cliquez sur le bouton 'Valider', la gestion de cette liste sera transférée à son bénéficiaire
            et le message suivant sera envoyé
        </p>
        <p>{{ message }}</p>

        <p [ngClass]="{ display: !transfertStatus }" class="message">
            Lien envoyé au bénéficiaire : lors de sa première connexion, vous n'aurez plus accès à cette liste.
        </p>
    </div>
    <div class="popin__action">
        <div>
            <mv-button
                label="Annuler"
                [color]="brand == 'mv' ? 'raspberry' : 'primary'"
                (click)="annuleTranferList()"
            ></mv-button>
        </div>
        <div>
            <mv-button
                disabled="{{ transfertStatus }}"
                label="Valider"
                [color]="brand == 'mv' ? 'raspberry' : 'primary'"
                (click)="transferList()"
            ></mv-button>
        </div>
    </div>
</mv-popup>
