import { Component, OnInit } from "@angular/core";
import { Brand, IPopinNicePresent, IpopinStatus, Type } from "@models/interfaces";
import { OffredListService } from "@modules/dashboard/offeredList/offredList.service";
import { BrandService } from "@shared/services/brand.service";
import { PopinNicePresentService } from "@shared/services/popinNicePresent.service";
import { TypeService } from "@shared/services/type.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "mv-popin-nicepresent",
    styleUrls: ["./popinNicePresent.component.scss"],
    templateUrl: "./popinNicePresent.component.html",
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PopinNicePresent implements OnInit {
    public brand: Brand;
    public type: Type;
    public displayPopin: boolean;
    public message: string;
    public cryptedId: string;
    public transfertStatus: boolean = false;

    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private brandService: BrandService,
        private typeService: TypeService,
        private offredListService: OffredListService,
        public popinNicePresentService: PopinNicePresentService,
    ) {}

    ngOnInit() {
        this.brandService.brand$.pipe(takeUntil(this.destroy$)).subscribe((brandVal) => {
            this.brand = brandVal;
        });

        this.typeService.type$.pipe(takeUntil(this.destroy$)).subscribe((typeVal) => {
            this.type = typeVal;
        });
        this.popinNicePresentService.dataPopin$.subscribe((data: IPopinNicePresent) => {
            this.displayPopin = data.displayPopin;
            this.message = data.messagePopin;
            this.cryptedId = data.cryptedId;
        });
    }

    public transferList() {
        if (!this.transfertStatus) {
            this.offredListService.transferList(this.cryptedId).subscribe((data: IpopinStatus) => {
                this.transfertStatus = data.status;
            });
        }
    }
    public annuleTranferList() {
        this.popinNicePresentService.toggle();
    }
}
