import { Injectable } from "@angular/core";
import { IsContractData } from "@models/index";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ContractDataService {
    private readonly isContractDataSource: BehaviorSubject<object> = new BehaviorSubject({});
    public isContractData$: Observable<object> = this.isContractDataSource.asObservable();

    public constructor() {}

    public setContractData(data: IsContractData) {
        this.isContractDataSource.next(data);
    }
}
