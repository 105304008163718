import { Component, OnInit } from "@angular/core";
import { Type, Brand } from "@models/interfaces";
import { BrandService } from "@shared/services/brand.service";
import { LoaderGcvService } from "@shared/services/loaderGcv.service";
import { TypeService } from "@shared/services/type.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "mv-contract-gcv",
    styleUrls: ["./contractGcv.component.scss"],
    templateUrl: "./contractGcv.component.html",
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ContractGcv implements OnInit {
    public brand: Brand;
    public type: Type;
    public travelContext: string;
    public brandContext: string;
    public domaineUrl: string;

    private readonly destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private brandService: BrandService,
        private typeService: TypeService,
        public loaderGcvService: LoaderGcvService,
    ) {}

    ngOnInit() {
        this.brandService.brand$.pipe(takeUntil(this.destroy$)).subscribe((brandVal) => {
            brandVal === "mv" ? (this.domaineUrl = ".marcovasco.fr") : (this.domaineUrl = ".prestige-voyages.com");
            this.brand = brandVal;
        });

        this.typeService.type$.pipe(takeUntil(this.destroy$)).subscribe((typeVal) => {
            this.type = typeVal;
        });
        this.travelContext = this.type === "travel" ? "voyage" : "mariage";
        this.brandContext = this.brand === "mv" ? "Marco Vasco" : "Prestige Voyages";
    }
}
