<mv-popup [show]="popinMsgDestinationService.dataPopin$ | async" (popin-closed)="popinMsgDestinationService.toggle()">
    <div class="msg-destination">
        <p>
            Donnez une touche personnelle à votre voyage en indiquant votre "destination" préférée ! Nous sommes
            impatients de la découvrir et de créer une expérience sur mesure rien que pour vous.
        </p>
        <mv-button
            label="Valider"
            [color]="brand == 'mv' ? 'raspberry' : 'primary'"
            (click)="togglePopin()"
        ></mv-button>
    </div>
</mv-popup>
