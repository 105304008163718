import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { Brand } from "@models/interfaces";

@Injectable({
    providedIn: "root",
})
export class BrandService {
    public brand$: Observable<Brand>;

    private readonly brandSource = new ReplaySubject<Brand>(1);

    constructor() {
        this.brand$ = this.brandSource.asObservable();
    }

    public updateBrand(brand: Brand) {
        this.brandSource.next(brand);
    }
}
