import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardGuard } from "@modules/dashboard/dashboard.guard";
import { PageNotFoundComponent } from "@shared/components/page-not-found/page-not-found.component";

export const routes: Routes = [
    { path: "not-found", component: PageNotFoundComponent, pathMatch: "full" },
    {
        loadChildren: () => import("./modules/home/home.module").then((m) => m.HomeModule),
        path: "",
    },
    {
        loadChildren: () => import("./modules/lostPassword/lostPassword.module").then((m) => m.LostPasswordModule),
        path: "transfert/:cryptedId",
    },
    {
        loadChildren: () => import("./modules/register/register.module").then((m) => m.RegisterModule),
        path: "inscription",
    },
    {
        loadChildren: () => import("./modules/contribute/contribute.module").then((m) => m.ContributeModule),
        path: "contribution/previsualisation/:cryptedId",
    },
    {
        loadChildren: () => import("./modules/contribute/contribute.module").then((m) => m.ContributeModule),
        path: "contribution/:cryptedId",
    },

    {
        loadChildren: () => import("./modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
        path: "ma-liste",
        canActivate: [DashboardGuard],
    },
    {
        loadChildren: () => import("./modules/lostPassword/lostPassword.module").then((m) => m.LostPasswordModule),
        path: "mot-de-passe-oublie",
    },
    {
        loadChildren: () => import("./modules/lostPassword/lostPassword.module").then((m) => m.LostPasswordModule),
        path: "mot-de-passe-oublie/:token",
    },
    {
        loadChildren: () => import("./modules/nicePresent/nicePresent.module").then((m) => m.NicePresentModule),
        path: "bon-cadeau/:cryptedId",
    },

    { path: "contribute", redirectTo: "contribution" },
    { path: "register", redirectTo: "inscription" },
    { path: "reset-password", redirectTo: "mot-de-passe-oublie" },
    { path: "**", redirectTo: "/not-found" },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: "top",
            // relativeLinkResolution: "legacy",
        }),
    ],
})
export class AppRoutingModule {}
