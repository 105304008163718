import { Injectable } from "@angular/core";

import { environment } from "@environments/environment";

import { Config, IConfigProperty } from "@models/index";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    protected config: Config;

    public constructor() {
        this.config = new Config(environment);
    }

    public get(key: string): IConfigProperty | undefined {
        return this.config.get(key);
    }
}
