<mv-popup show="{{ loaderGcvService.isOpen$ | async }}" (popin-closed)="loaderGcvService.toggle()" width="100%">
    <div class="ui-dialog-content ui-widget-content">
        <p>
            <span>Les </span>
            <a target="_blank" href="http://www{{ domaineUrl }}/conditions-generales-de-vente.html"
                >Conditions Générales</a
            >
            <span>&nbsp;et&nbsp;</span>
            <a target="_blank" href="http://www{{ domaineUrl }}/conditions-particulieres-de-vente-25-01-2017.html"
                >Conditions Particulières de vente</a
            >
            <span>&nbsp;de&nbsp;</span><span>{{ brandContext }}&nbsp;</span>
            <span>s'appliquent au cas particuliers des listes de {{ travelContext }}.</span>
        </p>
        <p>
            Les clients s'engagent à effectuer le voyage tel que décrit dans le bulletin d'inscription, pour ce faire,
            ils acquittent au moment de la signature du bulletion d'inscription un acompte d'un montant de 35% de la
            somme totale due.
        </p>
        <p>
            <strong>
                Un montant d'acompte supplémentaire peut toutefois être dû par la suite si les dates d'émission des
                billets d'avion et/ou les conditions de réservation des prestations terrestres devaient être amenées à
                évoluer.
            </strong>
            <span>
                Si ce montant n'a pas été versé à la date convenue avec l'agence, le voyage sera susceptible de
                modification tarifaire à la hausse.
            </span>
        </p>
        <p>
            <span>
                L'agence s'engage à encaisser tous les moyens de paiement lui étant adressés, carte bancaire; chèques à
                son ordre; espèces dans la limite de 1000 € et réglant une partie du dit voyage pour le compte des
                bénéficiaires. Les bénéficiaires demandent aux donateurs d'adresser les règlements à l'ordre de
            </span>
            <span>&nbsp;{{ brandContext }}&nbsp;</span>
            <span> accompagnés du nom des voyageurs et du n° de dossier voyage. </span>
        </p>
        <p>
            L'agence s'engage à informer régulièrement les bénéficiaires de l'évolution du paiement du voyage en
            adressant un récapitulatif à la demande des bénéficiaires (à défaut mensuellement si aucune demande
            spécifique n'est adressée) et à communiquer les noms des personnes ayant abondé la liste de
            {{ travelContext }}.
        </p>
        <p>
            <strong>
                A 35 jours de la date de départ, l'agence adresse aux bénéficiaires le montant du solde restant
                (accompagné sur demande de la facture détaillée des versements).
            </strong>
            <br />
            <span>
                Les bénéficiaires utilisent leur cagnotte pour régler ce montant et complètent le versement si
                nécessaire afin de recevoir leur carnet de voyage et les billets d'avion. Les contributeurs peuvent
                abonder la cagnotte jusqu'au jour du départ du voyage. Il appartient aux bénéficiaires d'informer les
                donateurs de la date limite de versement via la liste de {{ travelContext }}
            </span>
            <span>&nbsp;{{ brandContext }}</span>
        </p>
        <p>
            <strong>Cas particuliers : </strong><br />
            <span>Les sommes encaissées sont supérieures au montant total du voyage.</span><br />
            <span
                >Selon l'option choisie par le client à la signature du contrat : elles seront remboursées au client,
                déduction faite de 2.5% de frais de gestion par
            </span>
            <span>{{ brandContext }}.</span><br />
            <span
                >Les clients peuvent aussi décider de l'achat de prestations complémentaires (sur-classement, activités
                sur places, etc.), ou conserver cette somme sous forme d'avoir sur un prochain voyage. Attention, dans
                ce dernier cas, la validité de l'avoir, conformément à la loi, ne saurait dépasser une validité de deux
                années à compter des dates du voyage effectué.</span
            >
            <br /><br />

            <strong>En cas d'annulation : </strong><br />
            <span>
                En cas d'annulation, si le montant de l'acompte ne couvre pas les frais d'annulation, l'agence peut être
                amenée à exiger des clients un complément d'acompte lié aux frais d'annulation.
            </span>
        </p>
        <p>
            <span>Si le client ne souhaite plus réserver de voyage avec </span>
            <span>{{ brandContext }}</span>
            <span
                >, qu'aucun bulletin d'inscription n'a été signé et qu'il dispose d'une somme collectée via sa liste de
                {{ travelContext }}, il peut récupérer cette somme moyennant l'application d'une commission de 2.5%. La
                récupération de cette somme clôture la liste de {{ travelContext }}.</span
            >
        </p>
    </div>
</mv-popup>
