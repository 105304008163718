import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { Type } from "@models/interfaces";

@Injectable({
    providedIn: "root",
})
export class TypeService {
    public type$: Observable<Type>;

    private readonly typeSource = new ReplaySubject<Type>(1);

    constructor() {
        this.type$ = this.typeSource.asObservable();
    }

    public updateType(value: Type) {
        this.typeSource.next(value);
    }
}
