import { Component, OnInit } from "@angular/core";

@Component({
    selector: "mv-page-not-found",
    styleUrls: ["./page-not-found.component.scss"],
    templateUrl: "./page-not-found.component.html",
})
export class PageNotFoundComponent implements OnInit {
    public errorMessage: string;

    public ngOnInit() {
        const message = window.history?.state?.errorMessage;
        this.errorMessage = message == null ? "" : ` : ${message}`;
    }
}
